<template>
  <div class="container h-100-vh">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 v-text="  newDiscount.id ? 'Update discount' : 'Create new discount' "> </h5>
      </div>
      <div class="card-body">
        <div v-if=" !isLoading ">
          <v-observer tag="form" class=" animated fadeIn" ref="formDiscount" @submit.prevent="fnValidateDiscount">
            <div class="row">
              <div class="col-12 col-lg-8 ">
                <div class="row">

                  <div class="col-12 col-md-6 ">
                    <v-validation rules="required|min:3|max:20" v-slot="{ errors }" :name=" $t('general.form.name') ">
                      <div class="form-group mt-2">
                        <label for=""> <strong v-text=" $t('general.form.discountName') "> </strong> </label>
                        <input type="text" class="form-control" v-model=" newDiscount.name "
                          :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="invalid-feedback " v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </v-validation>
                  </div>
                  <div class="col-12"></div>

                  <div class="col-12 col-lg-12">
                    <v-validation rules="required" v-slot="{ errors }" :name=" 'Types' ">
                      <p class="mb-1"> <strong v-text=" 'Type' "> </strong></p>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" id="typePercent" value="percent"
                              v-model=" newDiscount.type ">
                            <label class="form-check-label" for="typePercent" v-text=" 'Percent' "> </label>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" id="typeFreeShipping" value="free shipping"
                              v-model=" newDiscount.type ">
                            <label class="form-check-label" for="typeFreeShipping" v-text=" 'Free shipping' "> </label>
                          </div>
                        </div>
                      </div>
                      <div class="invalid-feedback " v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </v-validation>
                  </div>
                  <div class="col-12 col-md-6">
                    <div v-show=" newDiscount.type == 'percent' " class="animated fadeIn">
                      <p class="mb-1"> <strong v-text=" 'Value' "> </strong></p>
                      <v-validation :rules=" newDiscount.type == 'percent' ? 'required|min_value:1|max_value:90' : '' "
                        v-slot="{ errors }" :name=" 'Discount value' ">
                        <div class="form-group ">
                          <label for=""> <small v-text=" 'Discount value' "> </small> </label>
                          <input type="number" class="form-control" v-model=" newDiscount.percent "
                            :class=" { 'is-invalid':errors.length > 0 } ">
                          <div class="invalid-feedback " v-if="errors.length > 0">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </v-validation>
                    </div>
                  </div>

                  <div class="col-12"></div>

                  <div class="col-12 col-xl-12">
                    <v-validation :rules=" newDiscount.type == 'percent' ? 'required' : '' " v-slot="{ errors }"
                      :name=" 'Applies to' ">
                      <p class="mb-1"> <strong v-text=" 'Applies to' "> </strong></p>

                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="row">
                            <div class="col">
                              <div class="form-check">
                                <input class="form-check-input" @click="modalProductList = !modalProductList"
                                  type="radio" id="typeProducts" value="products" v-model=" newDiscount.applies ">
                                <label class="form-check-label" for="typeProducts" v-text=" 'Products' "> </label>
                              </div>
                            </div>
                            <div class="  col-auto ml-auto ">
                              <button type="button" class="btn btn-primary btn-sm p-0 px-1 animated fadeIn "
                                @click=" modalProductList = !modalProductList "
                                v-show="newDiscount.applies == 'products' ">
                                <span v-text=" 'Search' "></span> <i class=" fa fa-search ml-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="row">
                            <div class="col">
                              <div class="form-check">
                                <input class="form-check-input" @click="modalStoreList = !modalStoreList" type="radio"
                                  id="typeStores" value="stores" v-model=" newDiscount.applies ">
                                <label class="form-check-label" for="typeStores" v-text=" 'Stores' "> </label>
                              </div>
                            </div>
                            <div class="col-auto ml-auto ">
                              <button type="button" class="btn btn-primary btn-sm p-0 px-1 animated fadeIn"
                                @click=" modalStoreList = !modalStoreList " v-show="newDiscount.applies == 'stores' ">
                                <span v-text=" 'Search' "></span> <i class=" fa fa-search ml-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="invalid-feedback " v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </v-validation>

                    <div v-show=" newDiscount.applies == 'stores' ">
                      <v-validation :rules=" newDiscount.applies == 'stores' ? 'required' : '' " v-slot="{ errors }"
                        :name=" 'Store list' ">
                        <input type="checkbox" value="storeListCheck" v-model=" newDiscount.storeListCheck "
                          class="form-control m-0" :class=" { 'is-invalid':errors.length > 0 } " hidden>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </v-validation>
                    </div>
                    <div v-show=" newDiscount.applies == 'products' ">
                      <v-validation :rules=" newDiscount.applies == 'products' ? 'required' : '' " v-slot="{ errors }"
                        :name=" 'Products list' ">
                        <input type="checkbox" value="productListCheck" v-model="newDiscount.productListCheck "
                          class="form-control m-0" :class=" { 'is-invalid':errors.length > 0 } " hidden>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </v-validation>
                    </div>

                  </div>

                  <div class="col-12">
                    <hr>
                  </div>

                  <div class="col-12 ">
                    <p class="mb-0"> <strong> Dates </strong> </p>
                    <div class="row">
                      <div class="col-12 col-sm-6 ">
                        <div class="form-group">
                          <label for="" v-text=" 'Initial date' "></label>
                          <v-validation rules="required" v-slot="{ errors }" :name=" 'Init date' ">
                            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY"
                              @input="fnDateNotBeforeTo() ; fnStringDates()" right v-model="newDiscount.dates.from"
                              :min-date=" CONST.TODAY " no-header only-date no-button-now dark no-button auto-close>
                              <input type="text" class="form-control" v-model=" stringDates.start ">
                            </v-date-picker>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </v-validation>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 ">
                        <div class="form-group">
                          <label for="" v-text=" 'Finish date' "></label>
                          <v-validation rules="required" v-slot="{ errors }" :name=" 'Finish date'">
                            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" right
                              @input=" fnStringDates() " v-model="newDiscount.dates.to" :min-date="notBefore" no-header
                              only-date no-button-now dark no-button auto-close>
                              <input type="text" class="form-control" v-model=" stringDates.end ">
                            </v-date-picker>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </v-validation>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-12 col-lg-4 ">
                <div class="p-2 mb-3 rounded border border-success bg-light ">
                  <h5 v-text=" 'Summary' "> </h5>
                  <hr class="m-2">
                  <p class="m-0 text-truncate " v-if=" newDiscount.name != null "> <span v-text=" 'Name: ' "></span>
                    <strong v-text=" newDiscount.name "> </strong></p>

                  <div v-if=" newDiscount.type == 'percent'">

                    <div class="row">
                      <div class="col-6  col-lg-12 col-xl-6 ">
                        <p> <span v-text=" 'Type: ' "></span> <strong v-text=" 'Percent' "> </strong></p>
                      </div>
                      <div class="col-6 col-lg-12 col-xl-6 ">
                        <p v-if=" newDiscount.type == 'percent'" v-show=" newDiscount.percent > 0"> <span
                            v-text=" 'Discount: ' "></span> <strong v-text=" newDiscount.percent + '%' "> </strong></p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 col-lg-12 col-xl-6 ">
                        <p>
                          <span v-text=" 'Applies to: ' "></span>
                          <strong v-if=" newDiscount.applies == 'stores' " v-text=" 'Stores' "> </strong>
                          <strong v-if=" newDiscount.applies == 'products' " v-text=" 'Products' "> </strong>
                        </p>
                      </div>
                      <div class="col-6 col-lg-12 col-xl-6 ">
                        <p v-if=" newDiscount.applies == 'stores' "> <span v-text=" 'Total stores: ' "></span> <strong
                            v-text="  (tempData.tempStoreList.length) ? tempData.tempStoreList.length : 'None'  ">
                          </strong> </p>
                        <p v-else> <span v-text=" 'Total products: ' "></span> <strong
                            v-text="  (tempData.tempProductList.length) ? tempData.tempProductList.length : 'None'  ">
                          </strong> </p>
                      </div>
                    </div>

                  </div>
                  <div v-else>
                    <p class="m-0"> <span v-text=" 'Type: ' "></span> <strong v-text=" 'Free shipping' "> </strong></p>
                    <p v-if=" newDiscount.applies == 'stores' "> <span v-text=" 'Total stores: ' "></span> <strong
                        v-text="  (tempData.tempStoreList.length) ? tempData.tempStoreList.length : 'None'  "> </strong>
                    </p>
                    <p v-else> <span v-text=" 'Total products: ' "></span> <strong
                        v-text="  (tempData.tempProductList.length) ? tempData.tempProductList.length : 'None'  ">
                      </strong> </p>
                  </div>

                  <hr class="my-2">
                  <div class="row">
                    <div class="col-6">
                      <p> <span v-text=" 'From: ' "></span> <strong v-text=" stringDates.start "> </strong> </p>
                    </div>
                    <div class="col-6">
                      <p> <span v-text=" 'To: ' "></span> <strong v-text=" stringDates.end "> </strong> </p>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-12 col-lg-8 text-right ">
                <button class=" btn btn-primary " :disabled="sendingData">
                  <span v-text=" newDiscount.id ? $t('general.button.update') : $t('general.button.create') "
                    v-if=" !sendingData "></span>
                  <i class="fa fa-spinner fa-spin " v-else></i>
                </button>
              </div>
            </div>
            <input type="submit" hidden>
          </v-observer>
        </div>
        <no-data :dataMessage="$t('noDataMessages.errorDefault')" v-show=" isLoading " :isLoading="isLoading"
          :showBtn="false"></no-data>

      </div>
    </div>

    <product-list :showModalProducts=" modalProductList " :products="tempData.tempProductList"
      @update-list=" fnUpdateProductList  "></product-list>
    <store-list :showModalStores=" modalStoreList " :stores="tempData.tempStoreList"
      @update-list=" fnUpdateStoreList  "></store-list>
    <!-- <pre> {{ tempData }}  </pre> -->
    <!-- <pre> {{ tempData.tempStoreList }}  </pre>
        <pre> {{tempData.tempProductList }}  </pre> -->
  </div>
</template>

<script>
  import storeList from './components/store_list_mdoal';
  import productList from './components/product_list_mdoal';
  export default {
    components: {
      storeList,
      productList,
    },
    data() {
      return ({
        isLoading: false,
        modalStoreList: false,
        modalProductList: false,
        sendingData: false,
        discount: [],
        stringDates: {
          start: null,
          end: null,
        },
        notBefore: null,
        // NEW DISCOUNT
        newDiscount: {
          id: null,
          name: null,
          type: 'percent',
          percent: 5,
          applies: 'products',
          // requirements: 'none',
          dates: {
            from: null,
            to: null,
          },
          storeListCheck: [],
          productListCheck: [],
        },
        tempData: {
          tempStoreId: [],
          tempStoreList: [],
          tempProductList: [],
          tempCounryList: [],
        },
        // PAGINATIONS
        // DATA
        countryList: [],
      })
    },

    methods: {
      // Store list and product list
      fnUpdateStoreList(storeList) {
        this.tempData.tempStoreList = storeList;
        if (storeList.length > 0) {
          this.newDiscount.storeListCheck = ['storeListCheck'];
        } else {
          this.newDiscount.storeListCheck = [];
        }
      },
      fnUpdateProductList(productList) {
        this.tempData.tempProductList = productList;
        if (productList.length > 0) {
          this.newDiscount.productListCheck = ['productListCheck'];
        } else {
          this.newDiscount.productListCheck = [];
        }
      },
      // Discount dates
      fnResetProductDiscount() {
        this.newDiscount.dates.from = new Date().setHours(0, 0, 0, 0);
        this.newDiscount.dates.to = this.$moment(this.newDiscount.dates.from).add(30, 'days').format('x');
        this.newDiscount.dates.from = this.$moment(this.newDiscount.dates.from).format('x');
        this.fnDateNotBeforeTo();
      },
      fnDateNotBeforeTo() {
        this.notBefore = this.$moment(this.newDiscount.dates.from, 'x').add(1, 'days').format();
        this.fnDateResetFinishDate();
        this.fnStringDates();
      },
      fnDateResetFinishDate() {
        this.newDiscount.dates.to = this.$moment(this.newDiscount.dates.from, 'x').add(30, 'days').format('x');
      },
      fnStringDates() {
        this.stringDates.start = this.$moment(this.newDiscount.dates.from, 'x').format(this.CONST.DATE_FORMATS
          .DD_MM_YYYY);
        this.stringDates.end = this.$moment(this.newDiscount.dates.to, 'x ').format(this.CONST.DATE_FORMATS.DD_MM_YYYY);
      },

      // Validate and send discount
      async fnValidateDiscount() {
        if (await this.$refs.formDiscount.validate()) {
          let discount = {};
          discount.name = this.newDiscount.name;
          discount.type = this.newDiscount.type;

          discount.from = this.newDiscount.dates.from;
          discount.to = this.newDiscount.dates.to;

          if (this.newDiscount.type == 'percent') {
            discount.percent = this.newDiscount.percent;
          }
          if (this.newDiscount.applies == 'stores') {
            discount.store_ids = this.tempData.tempStoreList;
            discount.product_ids = [];
          } else {
            discount.product_ids = this.tempData.tempProductList;
            discount.store_ids = [];
          }
          if (this.newDiscount.id) {
            await axios.put('discounts/' + this.$route.params.id, discount).then(response => {
              this.$toasted.global.infoMessage(this.$t('toasted.info.discountUpdated'));
              this.$router.push('/dashboard/discounts');
            }).catch(error => {});
          } else {
            await axios.post('discounts', discount).then(response => {
              this.$toasted.global.infoMessage(this.$t('toasted.info.discountCreate'));
              this.$router.push('/dashboard/discounts');
            }).catch(error => {});
          }

        }
      },
      // Get discount by id
      async fnApiGetDiscount() {
        this.isLoading = true;
        await axios.get('discounts/' + this.$route.params.id).then(response => {
          this.newDiscount.id = response.data.id;
          this.newDiscount.type = response.data.type;
          this.newDiscount.name = response.data.name;
          this.newDiscount.percent = response.data.percent;
          this.newDiscount.dates.from = this.$moment(response.data.from).format('x');
          this.newDiscount.dates.to = this.$moment(response.data.to).format('x');
          this.fnStringDates();
          if (response.data.store_ids.length > 0) {
            this.newDiscount.applies = 'stores';
          } else {
            this.newDiscount.applies = 'products';
          }
          this.fnUpdateProductList(response.data.product_ids);
          this.fnUpdateStoreList(response.data.store_ids);
        }).catch(error => {});
        this.isLoading = false;
      },
    },
    async mounted() {
      this.fnResetProductDiscount()
      if (this.$route.params.id) {
        this.fnApiGetDiscount();
      }
    }
  }
</script>